import communLangage from '@/commun/commun.langage.js';

export default {
     name: 'Competences',

     data() {
          return {
               afficherPlus: false,
               titres:{}
          }
     },

     computed: {
          langage() {
               return communLangage.langage;
          },
          competences(){
               return communLangage.competences;
          }
     },

     methods: {
          
     },

     

     created() {
     }
}