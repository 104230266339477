<template>
  <div class="" data-aos="zoom-in" data-aos-duration="1500" v-if="competences">
    <div class="d-flex justify-content-around pr-2 pl-2 background-color-body d-flex-tel">
      <fieldset style="flex-basis: 30%" class="d-flex flex-wrap align-items-start justify-content-around m-2 mt-5">
        <legend class="float-none m-0">{{ competences.logiciels }}</legend>

        <img src="@/assets/logo/android-studio.png" alt="Android studio" title="Android studio" style="width: 100px" class="ml-2" />
        <img src="@/assets/logo/azure-data-studio.png" alt="Azure data studio" title="Azure data studio" style="width: 100px" class="ml-2" />
        <img src="@/assets/logo/filezilla.png" alt="FileZilla" title="FileZilla" style="width: 100px" class="ml-2" />
        <img src="@/assets/logo/visual-studio.png" alt="Visual studio 2021" title="Visual studio 2021" style="width: 100px" class="ml-2" />
        <img src="@/assets/logo/visual-studio-code.png" alt="Visual studio code" title="Visual studio code" style="width: 100px" class="ml-2" />
        <img src="@/assets/logo/gantt-project.png" alt="Gantt project" title="Gantt project" style="width: 100px" class="ml-2" />
        <img src="@/assets/logo/github.png" alt="Github" title="Github" style="width: 100px" class="ml-2" />
        <img src="@/assets/logo/gitlab.png" alt="Gitlab" title="Gitlab" style="width: 100px" class="ml-2" />
        <img src="@/assets/logo/svn.png" alt="SVN (apache subversions)" title="SVN (apache subversions)" style="width: 100px" class="ml-2" />
      </fieldset>
      <fieldset style="flex-basis: 20%" class="d-flex flex-wrap align-items-start justify-content-around m-2 mb-5">
        <legend class="float-none m-0">{{ competences.systemes }}</legend>
        <img src="@/assets/logo/windows.png" alt="Windows" title="Windows" style="width: 100px" class="m-1" />
        <img src="@/assets/logo/linux.png" alt="Linux" title="Linux" style="width: 100px" class="ml-2" />
        <img src="@/assets/logo/android.png" alt="Android" title="Android" style="width: 100px" class="ml-2" />
      </fieldset>
      <div style="flex-basis: 30%" class="mr-2 ml-2 mb-2 margin">
        <fieldset class="d-flex flex-wrap align-items-start justify-content-around mt-5">
          <legend class="float-none m-0">{{ competences.langages }}</legend>

          <img src="@/assets/logo/c-sharp.png" alt="C#" title="C#" style="width: 100px" class="ml-2" />
          <img src="@/assets/logo/js-5.png" alt="JS 5" title="JS 5" style="width: 100px" class="ml-2" />
          <img src="@/assets/logo/html-5.png" alt="HTML 5" title="HTML 5" style="width: 100px" class="ml-2" />
          <img src="@/assets/logo/css-3.png" alt="CSS 3" title="CSS 3" style="width: 100px" class="ml-2" />
          <img src="@/assets/logo/python.png" alt="Python" title="Python" style="width: 100px" class="ml-2" />
          <img src="@/assets/logo/postgresql.png" alt="postreSql" title="postreSql" style="width: 100px" class="ml-2" />
          <img src="@/assets/logo/mariadb.png" alt="MariaDb" title="MariaDb" style="width: 100px" class="ml-2" />
        </fieldset>
        <fieldset class="d-flex flex-wrap align-items-start justify-content-around">
          <legend class="float-none m-0">{{ competences.autresLangages }}</legend>

          <img src="@/assets/logo/java.png" alt="Java" title="Java" style="width: 100px" class="ml-2" />
          <img src="@/assets/logo/c.png" alt="Langage C" title="Langage C" style="width: 100px" class="ml-2" />
          <img src="@/assets/logo/asm.png" alt="Assembleur" title="Assembleur" style="width: 100px" class="ml-2" />
          <img src="@/assets/logo/cmd.png" alt="bash linux" title="bash linux" style="width: 100px" class="ml-2" />
          <img src="@/assets/logo/DOS.png" alt="DOS" title="DOS" style="width: 100px" class="ml-2" />
        </fieldset>
      </div>
      <fieldset style="flex-basis: 20%" class="d-flex flex-wrap align-items-start justify-content-around m-2 mb-5">
        <legend class="float-none m-0">{{ competences.frameworks }}</legend>

        <img src="@/assets/logo/vuejs.png" alt="VueJs" title="VueJs" style="width: 100px" class="ml-2" />
        <img src="@/assets/logo/bootstrap.png" alt="Bootstrap" title="Bootstrap" style="width: 100px" class="ml-2 mt-4" />
        <img src="@/assets/logo/dot-net.png" alt="Environnement .NET" title="Environnement .NET" style="width: 100px" class="ml-2 mt-4" />
      </fieldset>
    </div>
  </div>
</template>

<script src="./competences.js"></script>

<style scoped>
fieldset {
  background-color: rgba(201, 198, 198, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-radius: 0.25rem;
}

img {
  margin-top: 3%;
}

@media (max-width: 600px) {
  fieldset {
    margin: 0.2rem !important;
  }
  .margin {
    margin: 0 !important;
  }
}
</style>
